import { AxiosInstance } from 'axios';

import * as agendaApi from './agenda.api';
import * as aiToolkitApi from './ai-toolkit.api';
import * as analysisApi from './analysis.api';
import * as authApi from './auth.api';
import * as belgaContactsApi from './belga-contacts.api';
import boardApi from './board.api';
import * as brandApi from './brand.api';
import * as clippingApi from './clipping.api';
import * as commerceApi from './commerce.api';
import * as contactApi from './contact.api';
import * as contactGroupApi from './contact-group.api';
import * as contactTagApi from './contact-tag.api';
import * as currentFiltersApi from './current-filter.api';
import * as documentsApi from './documents.api';
import * as exploreApi from './explore.api';
import * as feedApi from './feed.api';
import * as feedbackApi from './feedback.api';
import gopressArchiveApi from './gopress-archive.api';
import * as journalistApi from './journalist.api';
import keywordApi from './keyword.api';
import * as labelApi from './label.api';
import * as maintenanceMessageApi from './maintenance-message.api';
import * as mediaApi from './media.api';
import * as mediumTypeGroupsApi from './mediumtypegroup.api';
import * as newsletterApi from './newsletter.api';
import newsletterItemApi from './newsletter-item.api';
import * as newsletterStatisticsApi from './newsletter-statistics.api';
import * as newsObjectApi from './newsobject.api';
import * as newsroomApi from './newsroom.api';
import * as organizationApi from './organization.api';
import * as pressReleasesApi from './press-release.api';
import * as publicApi from './public.api';
import * as publicationApi from './publication.api';
import * as publishersApi from './publishers.api';
import * as quickIntegrationApi from './quick-integration.api';
import recurringTemplateApi from './recurring-template.api';
import * as reportingApi from './reporting.api';
import * as reportsApi from './reports.api';
import * as salesApi from './sales.api';
import * as savedSearchApi from './saved-search.api';
import * as searchApi from './search.api';
import * as shareApi from './share.api';
import * as sourcesApi from './source.api';
import tagApi from './tag.api';
import * as tagSettingsApi from './tag-setting.api';
import templateApi from './template.api';
import templateItemApi from './template-item.api';
import topicApi from './topic.api';
import * as trackingApi from './tracking.api';
import * as userApi from './user.api';

interface Options {
  api: AxiosInstance;
}

class RestApiService {
  public api: AxiosInstance;

  constructor(options: Options) {
    this.api = options.api;
  }

  // KEYCLOAK
  public postExchangeCodeForTokens = authApi.postExchangeCodeForTokens;
  public refreshAuthToken = authApi.refreshAuthToken;
  public endSession = authApi.endSession;

  // BOARDS
  public fetchBoard = boardApi.fetchBoard;
  public fetchBoards = boardApi.fetchBoards;
  public createBoard = boardApi.createBoard;
  public updateBoard = boardApi.updateBoard;
  public deleteBoard = boardApi.deleteBoard;
  public fetchBoardSections = boardApi.fetchBoardSections;
  public fetchBoardNotificationSettings = boardApi.fetchBoardNotificationSettings;
  public updateBoardNotificationSettings = boardApi.updateBoardNotificationSettings;
  public orderUserBoards = boardApi.orderUserBoards;
  public detachBoard = boardApi.detachBoard;
  public fetchBoardAutolabels = boardApi.fetchBoardAutolabels;
  public updateBoardAutolabels = boardApi.updateBoardAutoLabels;
  public fetchBoardSourceSettings = boardApi.fetchBoardSourceSettings;
  public updateBoardSourceSettings = boardApi.updateBoardSourceSettings;

  // FEEDS
  public createFeed = feedApi.createFeed;
  public updateFeed = feedApi.updateFeed;
  public deleteFeed = feedApi.deleteFeed;
  public fetchUserFeeds = feedApi.fetchUserFeeds;
  public fetchUserKioskFeeds = feedApi.fetchUserKioskFeeds;
  public fetchFeedById = feedApi.fetchFeedById;
  public detachFeed = feedApi.detachFeed;
  public fetchFeedPublishSettings = feedApi.fetchFeedPublishSettings;
  public updateFeedPublishSettings = feedApi.updateFeedPublishSettings;
  public fetchKioskFeedNewsObjects = feedApi.fetchKioskFeedNewsObjects;
  public fetchFeedNotificationSettings = feedApi.fetchFeedNotificationSettings;
  public updateFeedNotificationSettings = feedApi.updateFeedNotificationSettings;
  public updateFeedCoverImage = feedApi.updateFeedCoverImage;
  public deleteFeedCoverImage = feedApi.deleteFeedCoverImage;
  public restoreFeedCoverImage = feedApi.restoreFeedCoverImage;

  // KEYWORDS
  public fetchOrganizationKeywords = keywordApi.fetchOrganizationKeywords;
  public createOrganizationKeyword = keywordApi.createOrganizationKeyword;
  public deleteOrganizationKeywords = keywordApi.deleteOrganizationKeywords;
  public updateOrganizationKeyword = keywordApi.updateOrganizationKeyword;

  // KIOSK
  public fetchDeliverables = publicationApi.fetchDeliverables;
  public fetchPublications = publicationApi.fetchPublications;
  public fetchFavouriteDeliverables = publicationApi.fetchFavouriteDeliverables;
  public fetchPublicationById = publicationApi.fetchPublicationById;
  public fetchDeliverableEditions = publicationApi.fetchDeliverableEditions;

  // TAG SETTINGS
  public createTagSettings = tagSettingsApi.createTagSettings;
  public updateTagSettings = tagSettingsApi.updateTagSettings;
  public deleteTagSettings = tagSettingsApi.deleteTagSettings;
  public fetchTagSettings = tagSettingsApi.fetchTagSettings;

  // USER
  public fetchSettings = userApi.fetchSettings;
  public updateSettings = userApi.updateSettings;
  public fetchCurrentUser = userApi.fetchCurrentUser;
  public fetchUser = userApi.fetchUser;
  public fetchUsers = userApi.fetchUsers;
  public createUsers = userApi.createUsers;
  public deleteUser = userApi.deleteUser;
  public updateUser = userApi.updateUser;
  public fetchExportUsers = userApi.fetchExportUsers;
  public checkEmail = userApi.checkEmail;
  public resetOnboarding = userApi.resetOnboarding;

  // ORGANIZATION
  public fetchOrganizationUsers = organizationApi.fetchOrganizationUsers;
  public fetchOrganizationDetails = organizationApi.fetchOrganizationDetails;
  public fetchOrganizationsForUser = organizationApi.fetchOrganizationsForUser;
  public fetchOrganizationGroups = organizationApi.fetchOrganizationGroups;

  // NEWSOBJECT
  public fetchNewsObject = newsObjectApi.fetchNewsObject;
  public fetchNewsObjects = newsObjectApi.fetchNewsObjects;
  public fetchNewsObjectsByPost = newsObjectApi.fetchNewsObjectsByPost;
  public fetchNewsObjectSnippets = newsObjectApi.fetchNewsObjectSnippets;
  public deleteNewsObjectFromTag = newsObjectApi.deleteNewsObjectFromTag;
  public updateNewsObjectKeywords = newsObjectApi.updateNewsObjectKeywords;
  public getNewsObjectKeywords = newsObjectApi.getNewsObjectKeywords;
  public getNewsObjectDuplicates = newsObjectApi.getNewsObjectDuplicates;
  public fetchRelatedNewsObjects = newsObjectApi.fetchRelatedNewsObjects;
  public updateNewsObjectTags = newsObjectApi.updateNewsObjectTags;
  public fetchNewsObjectsPrice = newsObjectApi.fetchNewsObjectsPrice;
  public createPurchaseNewsObjects = newsObjectApi.createPurchaseNewsObjects;
  public getPurchasedNewsObjects = newsObjectApi.getPurchasedNewsObjects;
  public fetchNewsObjectPressContacts = newsObjectApi.fetchNewsObjectPressContacts;
  public fetchNewsObjectMetaData = newsObjectApi.fetchNewsObjectMetaData;

  // SHARE
  public createShareNewsObject = shareApi.createShareNewsObject;

  // NEWSLETTERS
  public fetchNewsletterById = newsletterApi.fetchNewsletterById;
  public fetchNewsletterDraftStatus = newsletterApi.fetchNewsletterDraftStatus;
  public fetchNewsletterOutline = newsletterApi.fetchNewsletterOutline;
  public createNewsletter = newsletterApi.createNewsletter;
  public fetchUserNewsletters = newsletterApi.fetchUserNewsletters;
  public fetchNewsletterPublishSettings = newsletterApi.fetchNewsletterPublishSettings;
  public updateNewsletterPublishSettings = newsletterApi.updateNewsletterPublishSettings;
  public duplicateNewsletter = newsletterApi.duplicateNewsletter;
  public detachNewsletter = newsletterApi.detachNewsletter;
  public deleteNewsletter = newsletterApi.deleteNewsletter;
  public updateNewsletter = newsletterApi.updateNewsletter;
  public updateNewsletterFields = newsletterApi.updateNewsletterFields;
  public fetchUserKioskNewsletters = newsletterApi.fetchUserKioskNewsletters;
  public fetchNewsletterNewsObjects = newsletterApi.fetchNewsletterNewsObjects;
  public createNewsletterDraft = newsletterApi.createNewsletterDraft;
  public deleteNewsletterDraft = newsletterApi.deleteNewsletterDraft;
  public updateNewsletterDraft = newsletterApi.updateNewsletterDraft;
  public updateNewsletterCoverImage = newsletterApi.updateNewsletterCoverImage;
  public deleteNewsletterCoverImage = newsletterApi.deleteNewsletterCoverImage;
  public restoreNewsletterCoverImage = newsletterApi.restoreNewsletterCoverImage;
  public generateNewsletterSummary = newsletterApi.generateNewsletterSummary;

  // NEWSLETTER ITEMS
  public createNewsletterItem = newsletterItemApi.createNewsletterItem;
  public updateNewsletterItem = newsletterItemApi.updateNewsletterItem;
  public deleteNewsletterItem = newsletterItemApi.deleteNewsletterItem;
  public fetchNewsletterItemNewsObjects = newsletterItemApi.fetchNewsletterItemNewsObjects;
  public updateNewsletterItemNewsObjects = newsletterItemApi.updateNewsletterItemNewsObjects;
  public deleteNewsletterItemNewsObjects = newsletterItemApi.deleteNewsletterItemNewsObjects;
  public orderNewsletterItems = newsletterItemApi.orderNewsletterItems;
  public fetchNewsletterItemNewsObject = newsletterItemApi.fetchNewsletterItemNewsObject;
  public updateNewsletterItemNewsObject = newsletterItemApi.updateNewsletterItemNewsObject;
  public prefillNewsletterItem = newsletterItemApi.prefillNewsletterItem;
  public getPrefillNewsletterItemStatus = newsletterItemApi.getPrefillNewsletterItemStatus;
  public restoreNewsletterItemNewsObjectTitle =
    newsletterItemApi.restoreNewsletterItemNewsObjectTitle;
  public uploadNewsletterImage = newsletterItemApi.uploadNewsletterImage;

  // TEMPLATES
  public fetchTemplates = templateApi.fetchTemplates;
  public fetchTemplate = templateApi.fetchTemplate;
  public createTemplate = templateApi.createTemplate;
  public updateTemplate = templateApi.updateTemplate;
  public deleteTemplate = templateApi.deleteTemplate;
  public deleteTemplates = templateApi.deleteTemplates;
  public fetchTemplatePublishSettings = templateApi.fetchTemplatePublishSettings;
  public updateTemplatePublishSettings = templateApi.updateTemplatePublishSettings;
  public fetchTemplateSubscription = templateApi.fetchTemplateSubscription;
  public updateTemplateSubscription = templateApi.updateTemplateSubscription;
  public detachTemplate = templateApi.detachTemplate;
  public updateTemplateCoverImage = templateApi.updateTemplateCoverImage;
  public deleteTemplateCoverImage = templateApi.deleteTemplateCoverImage;
  public restoreTemplateCoverImage = templateApi.restoreTemplateCoverImage;

  // TEMPLATE ITEMS
  public createTemplateItem = templateItemApi.createTemplateItem;
  public updateTemplateItem = templateItemApi.updateTemplateItem;
  public updateTemplateItems = templateItemApi.updateTemplateItems;
  public deleteTemplateItem = templateItemApi.deleteTemplateItem;
  public orderTemplateItems = templateItemApi.orderTemplateItems;
  public updateTemplateItemFields = templateItemApi.updateTemplateItemFields;
  public uploadTemplateImage = templateItemApi.uploadTemplateImage;

  // REPORTS
  public createGraph = reportsApi.createGraph;
  public updateGraph = reportsApi.updateGraph;
  public fetchGraphById = reportsApi.fetchGraphById;

  // ANALYSIS
  public createSavedAnalysis = analysisApi.createSavedAnalysis;
  public updateSavedAnalysis = analysisApi.updateSavedAnalysis;
  public detachSavedAnalysis = analysisApi.detachSavedAnalysis;
  public deleteSavedAnalysis = analysisApi.deleteSavedAnalysis;
  public createAnalysisExport = analysisApi.createAnalysisExport;
  public fetchAnalysis = analysisApi.fetchAnalysis;
  public fetchAnalysisSentiment = analysisApi.fetchAnalysisSentiment;
  public fetchAnalysisArticleCount = analysisApi.fetchAnalysisArticleCount;
  public fetchAnalysisAudienceReached = analysisApi.fetchAnalysisAudienceReached;
  public fetchAnalysisMediaValue = analysisApi.fetchAnalysisMediaValue;
  public fetchAnalysisSources = analysisApi.fetchAnalysisSources;
  public fetchAnalysisSourceGroups = analysisApi.fetchAnalysisSourceGroups;
  public fetchUserSavedAnalyses = analysisApi.fetchUserSavedAnalyses;
  public fetchSavedAnalysisById = analysisApi.fetchSavedAnalysisById;
  public fetchDatasetConstraints = analysisApi.fetchDatasetConstraints;

  // DOCUMENTS
  public fetchUserDocuments = documentsApi.fetchUserDocuments;
  public createDocument = documentsApi.createDocument;
  public updateDocument = documentsApi.updateDocument;
  public uploadDocument = documentsApi.uploadDocument;
  public deleteDocument = documentsApi.deleteDocument;
  public detachDocument = documentsApi.detachDocument;

  // SOURCES
  public fetchSourceGroups = sourcesApi.fetchSourceGroups;
  public fetchSources = sourcesApi.fetchSources;
  public fetchSource = sourcesApi.fetchSource;
  public addFavoriteSource = sourcesApi.addFavoriteSource;
  public deleteFavoriteSource = sourcesApi.deleteFavoriteSource;
  public fetchSourceEditions = sourcesApi.fetchSourceEditions;
  public fetchAllSourcesEditions = sourcesApi.fetchAllSourcesEditions;
  public fetchSubsources = sourcesApi.fetchSubsources;
  public fetchOccurrences = publicationApi.fetchOccurrences;
  public fetchBelgaSources = sourcesApi.fetchBelgaSources;
  public fetchPublishersSources = sourcesApi.fetchPublishersSources;

  // MEDIUMTYPEGROUPS
  public fetchMediumTypeGroups = mediumTypeGroupsApi.fetchMediumTypeGroups;

  // MAINTENANCE MESSAGE
  public fetchMaintenanceMessages = maintenanceMessageApi.fetchMaintenanceMessages;

  // CURRENT FILTERS
  public fetchCurrentFilters = currentFiltersApi.fetchCurrentFilters;
  public updateCurrentFilters = currentFiltersApi.updateCurrentFilters;

  // SAVED SEARCH
  public fetchSavedSearches = savedSearchApi.fetchSavedSearches;
  public fetchSavedSearch = savedSearchApi.fetchSavedSearch;
  public createSavedSearch = savedSearchApi.createSavedSearch;
  public updateSavedSearch = savedSearchApi.updateSavedSearch;
  public deleteSavedSearch = savedSearchApi.deleteSavedSearch;

  // SEARCH
  public fetchSearchHistory = searchApi.fetchSearchHistory;
  public postSearchHistory = searchApi.postSearchHistory;

  // EXPLORE
  public fetchExploreSections = exploreApi.fetchExploreSections;

  // TAGS
  public fetchUserTags = tagApi.fetchUserTags;
  public createUserTag = tagApi.createUserTag;
  // public fetchTags = tagApi.fetchTags;

  public fetchUserTag = tagApi.fetchUserTag;

  public fetchNewsletterTags = tagApi.fetchNewsletterTags;

  // TOPICS
  public fetchTopics = topicApi.fetchTopics;
  public fetchUserTopics = topicApi.fetchUserTopics;
  public fetchAllTopicNotifications = topicApi.fetchAllTopicNotifications;
  public followTopic = topicApi.followTopic;
  public followTopics = topicApi.followTopics;
  public createTopicsNotifications = topicApi.createTopicsNotifications;
  public unFollowTopic = topicApi.unFollowTopic;
  public fetchTopicNotifications = topicApi.fetchTopicNotifications;
  public createTopicNotifications = topicApi.createTopicNotifications;
  public updateTopicNotifications = topicApi.updatetopicNotifications;
  public deleteTopicNotification = topicApi.deleteTopicNotification;

  // LABELS
  public fetchUserLabels = labelApi.fetchUserLabels;
  public createLabel = labelApi.createLabel;
  public updateLabel = labelApi.updateLabel;
  public deleteLabel = labelApi.deleteLabel;
  public clearLabel = labelApi.clearLabel;
  public orderUserLabels = labelApi.orderUserLabels;
  public detachLabel = labelApi.detachLabel;

  // CLIPPINGS
  public createClipping = clippingApi.createClipping;
  public deleteClipping = clippingApi.deleteClipping;
  public fetchRelatedClippingNewsObjects = clippingApi.fetchRelatedClippingNewsObjects;

  // QUICK INTEGRATION
  public fetchQuickIntegration = quickIntegrationApi.fetchQuickIntegration;
  public createQuickIntegration = quickIntegrationApi.createQuickIntegration;
  public deleteQuickIntegration = quickIntegrationApi.deleteQuickIntegration;

  // Contacts
  public fetchContacts = contactApi.fetchContacts;
  public fetchContactsAsyncTask = contactApi.fetchContactsAsyncTask;
  public createContacts = contactApi.createContacts;
  public createContactsAsyncTask = contactApi.createContactsAsyncTask;
  public updateContact = contactApi.updateContact;
  public deleteContact = contactApi.deleteContact;
  public deleteContacts = contactApi.deleteContacts;
  public fetchExportContacts = contactApi.fetchExportContacts;

  // Contact Groups
  public fetchContactGroups = contactGroupApi.fetchContactGroups;
  public fetchContactGroup = contactGroupApi.fetchContactGroup;
  public fetchDeleteContactGroupAsyncTask = contactGroupApi.fetchDeleteContactGroupAsyncTask;
  public fetchContactGroupContacts = contactGroupApi.fetchContactGroupContacts;
  public createContactGroup = contactGroupApi.createContactGroup;
  public updateContactGroup = contactGroupApi.updateContactGroup;
  public deleteContactGroup = contactGroupApi.deleteContactGroup;
  public addContactsToGroup = contactGroupApi.addContactsToGroup;
  public removeContactsFromGroup = contactGroupApi.removeContactsFromGroup;
  public detachContactGroup = contactGroupApi.detachContactGroup;

  // Contact Tags
  public fetchContactTags = contactTagApi.fetchContactTags;
  public createContactTag = contactTagApi.createContactTag;
  public updateContactTag = contactTagApi.updateContactTag;
  public deleteContactTag = contactTagApi.deleteContactTag;
  public clearContactTag = contactTagApi.clearContactTag;
  public assignContactTags = contactTagApi.assignContactTags;

  // Brands
  public fetchOrganizationBrand = brandApi.fetchOrganizationBrand;
  public fetchOrganizationBrands = brandApi.fetchOrganizationBrands;
  public createOrganizationBrand = brandApi.createOrganizationBrand;
  public updateOrganizationBrand = brandApi.updateOrganizationBrand;
  public deleteOrganizationBrand = brandApi.deleteOrganizationBrand;

  // GOPRESS ARCHIVE
  public fetchGopressFolders = gopressArchiveApi.fetchGopressFolders;
  public fetchGopressFolderOccurrences = gopressArchiveApi.fetchGopressFolderOccurrences;
  public updateGopressFolderTitle = gopressArchiveApi.updateGopressFolderTitle;
  public fetchGopressPressReview = gopressArchiveApi.fetchGopressPressReview;
  public fetchGopressPressReviewItemNewsObjects =
    gopressArchiveApi.fetchGopressPressReviewItemNewsObjects;
  public fetchGopressNewsObject = gopressArchiveApi.fetchGopressNewsObject;
  public fetchGopressNewsObjects = gopressArchiveApi.fetchGopressNewsObjects;

  // RECURRING TEMPLATES
  public fetchRecurringTemplates = recurringTemplateApi.fetchRecurringTemplates;
  public createRecurringTemplate = recurringTemplateApi.createRecurringTemplate;
  public updateRecurringTemplate = recurringTemplateApi.updateRecurringTemplate;
  public deleteRecurringTemplate = recurringTemplateApi.deleteRecurringTemplate;
  public deleteRecurringTemplates = recurringTemplateApi.deleteRecurringTemplates;
  public fetchRecurringTemplateOccurrences = recurringTemplateApi.fetchRecurringTemplateOccurrences;
  public fetchRecurringNewsletterNewsObjects =
    recurringTemplateApi.fetchRecurringNewsletterNewsObjects;

  // COMMERCE
  public fetchPurchasedArticlesInsights = commerceApi.fetchPurchasedArticlesInsights;
  public fetchExportPurchasedArticles = commerceApi.fetchExportPurchasedArticles;

  // Feedback
  public reportIssue = feedbackApi.reportIssue;

  // ACTIVITY REPORTS
  public fetchActivityReports = reportingApi.fetchActivityReports;

  // NEWSLETTER STATISTICS
  public fetchNewsletterStatistics = newsletterStatisticsApi.fetchNewsletterStatistics;
  public fetchRecipients = newsletterStatisticsApi.fetchRecipients;
  public fetchNewsletterRecipients = newsletterStatisticsApi.fetchNewsletterRecipients;
  public exportNewsletterRecipients = newsletterStatisticsApi.exportNewsletterRecipients;
  public fetchUniqueReaders = newsletterStatisticsApi.fetchUniqueReaders;
  public fetchOpenRates = newsletterStatisticsApi.fetchOpenRates;
  public fetchArticlesRead = newsletterStatisticsApi.fetchArticlesRead;
  public exportNewsletterStatistics = newsletterStatisticsApi.exportNewsletterStatistics;
  public exportRecipients = newsletterStatisticsApi.exportRecipients;

  // NEWSROOMS
  public fetchUserNewsrooms = newsroomApi.fetchUserNewsrooms;
  public fetchNewsroomById = newsroomApi.fetchNewsroomById;
  public fetchNewsroomSubSourceName = newsroomApi.fetchNewsroomSubSourceName;
  public createNewsroom = newsroomApi.createNewsroom;
  public updateNewsroom = newsroomApi.updateNewsroom;
  public publishNewsroom = newsroomApi.publishNewsroom;
  public unpublishNewsroom = newsroomApi.unpublishNewsroom;
  public deleteNewsroom = newsroomApi.deleteNewsroom;
  public detachNewsroom = newsroomApi.detachNewsroom;
  public restoreNewsroomCoverImage = newsroomApi.restoreNewsroomCoverImage;
  public restoreNewsroomProfilePicture = newsroomApi.restoreNewsroomProfilePicture;

  // NEWSROOM DOCUMENTS
  public fetchNewsroomDocuments = newsroomApi.fetchNewsroomDocuments;
  public createNewsroomDocument = newsroomApi.createNewsroomDocument;
  public updateNewsroomDocument = newsroomApi.updateNewsroomDocument;
  public deleteNewsroomDocument = newsroomApi.deleteNewsroomDocument;

  // NEWSROOM PRESS COVERAGES
  public fetchNewsroomPressCoverages = newsroomApi.fetchNewsroomPressCoverages;
  public createPressCoverages = newsroomApi.createPressCoverages;
  public updatePressCoverages = newsroomApi.updatePressCoverages;
  public deletePressCoverages = newsroomApi.deletePressCoverages;

  // NEWSROOM PRESS CONTACTS
  public fetchNewsroomPressContacts = newsroomApi.fetchNewsroomPressContacts;
  public createPressContact = newsroomApi.createPressContact;
  public createPressContactProfilePicture = newsroomApi.createPressContactProfilePicture;
  public deletePressContact = newsroomApi.deletePressContact;
  public updatePressContact = newsroomApi.updatePressContact;

  // NEWSROOM KIOSK
  public fetchKioskNewsrooms = newsroomApi.fetchKioskNewsrooms;
  public fetchKioskNewsroomById = newsroomApi.fetchKioskNewsroomById;
  public fetchKioskNewsroomDocuments = newsroomApi.fetchKioskNewsroomDocuments;
  public fetchKioskNewsroomEvents = newsroomApi.fetchKioskNewsroomEvents;
  public fetchKioskNewsroomEventById = newsroomApi.fetchKioskNewsroomEventById;
  public fetchKioskPressReleaseById = pressReleasesApi.fetchKioskPressReleaseById;
  public fetchKioskNewsroomPressReleases = pressReleasesApi.fetchKioskNewsroomPressReleases;
  public fetchPressReleasePickups = pressReleasesApi.fetchPressReleasePickups;
  public fetchPressReleaseRelatedNewsobjects = pressReleasesApi.fetchPressReleaseRelatedNewsobjects;
  public followNewsroom = newsroomApi.followNewsroom;
  public unFollowNewsroom = newsroomApi.unFollowNewsroom;
  public inviteToFollowNewsroom = newsroomApi.inviteToFollowNewsroom;

  // NEWSROOM EVENTS
  public createNewsroomEvent = newsroomApi.createNewsroomEvent;
  public updateNewsroomEvent = newsroomApi.updateNewsroomEvent;
  public deleteNewsroomEvent = newsroomApi.deleteNewsroomEvent;
  public fetchNewsroomEvents = newsroomApi.fetchNewsroomEvents;
  public fetchNewsroomEventById = newsroomApi.fetchNewsroomEventById;

  // NEWSROOM NOTIFICATIONS
  public createNewsroomNotification = newsroomApi.createNewsroomNotification;
  public updateNewsroomNotification = newsroomApi.updateNewsroomNotification;
  public deleteNewsroomNotification = newsroomApi.deleteNewsroomNotification;
  public fetchNewsroomNotification = newsroomApi.fetchNewsroomNotification;
  public fetchAllNewsroomNotifications = newsroomApi.fetchAllNewsroomNotifications;

  // AGENDA
  public fetchAgendaEvents = agendaApi.fetchAgendaEvents;
  public fetchAgendaEventById = agendaApi.fetchAgendaEventById;
  public fetchRubrics = agendaApi.fetchRubrics;
  public orderCoverage = agendaApi.orderCoverage;

  // PRESS RELEASES
  public createPressRelease = pressReleasesApi.createPressRelease;
  public fetchNewsroomPressReleases = pressReleasesApi.fetchNewsroomPressReleases;
  public fetchMinimalNewsroomPressReleases = pressReleasesApi.fetchMinimalNewsroomPressReleases;
  public deletePressRelease = pressReleasesApi.deletePressRelease;
  public fetchPressReleasePublishSettings = pressReleasesApi.fetchPressReleasePublishSettings;
  public duplicatePressRelease = pressReleasesApi.duplicatePressRelease;
  public fetchPressReleaseById = pressReleasesApi.fetchPressReleaseById;
  public updatePressRelease = pressReleasesApi.updatePressRelease;
  public publishPressRelease = pressReleasesApi.publishPressRelease;
  public unpublishPressRelease = pressReleasesApi.unpublishPressRelease;
  public fetchSectors = pressReleasesApi.fetchSectors;
  public updatePressReleasePublishSettings = pressReleasesApi.updatePressReleasePublishSettings;
  public fetchKioskPressReleases = pressReleasesApi.fetchKioskPressReleases;

  // PRESS RELEASES INSIGHTS
  public fetchPressReleasesInsightsGlobals = pressReleasesApi.fetchPressReleasesInsightsGlobals;
  public fetchPressReleasesInsights = pressReleasesApi.fetchPressReleasesInsights;
  public fetchPressReleaseInsightsDetail = pressReleasesApi.fetchPressReleaseInsightsDetail;

  // NEWSROOM INSIGHTS
  public fetchNewsroomInsightsGlobals = newsroomApi.fetchNewsroomInsightsGlobals;
  public fetchNewsroomUniqueVisitors = newsroomApi.fetchNewsroomUniqueVisitors;
  public fetchNewsroomPageActivity = newsroomApi.fetchNewsroomPageActivity;
  public fetchNewsroomFollowers = newsroomApi.fetchNewsroomFollowers;

  // MEDIA
  public uploadMedia = mediaApi.uploadMedia;

  // SALES
  public sendMailToSales = salesApi.sendMailToSales;

  // AI TOOLKIT
  public improveWriting = aiToolkitApi.improveWriting;
  public spellCheck = aiToolkitApi.spellCheck;
  public lengthenText = aiToolkitApi.lengthenText;
  public shortenText = aiToolkitApi.shortenText;
  public translate = aiToolkitApi.translate;

  // PUBLIC
  public createInquiry = publicApi.createInquiry;

  // TRACKING
  public trackBelgaShareEvent = trackingApi.trackBelgaShareEvent;

  // PUBLISHERS
  public fetchPublishers = publishersApi.fetchPublishers;

  // JOURNALISTS
  public fetchJournalists = journalistApi.fetchJournalists;
  public fetchJournalistById = journalistApi.fetchJournalistById;
  public fetchUserJournalistProfile = journalistApi.fetchUserJournalistProfile;
  public createJournalistProfile = journalistApi.createJournalistProfile;
  public updateJournalistProfile = journalistApi.updateJournalistProfile;
  public deleteJournalistProfile = journalistApi.deleteJournalistProfile;

  public restoreJournalistCoverImage = journalistApi.restoreJournalistCoverImage;

  public fetchJournalistPortfolioItems = journalistApi.fetchJournalistPortfolioItems;
  public updatePortfolioItems = journalistApi.updatePortfolioItems;
  public removePortfolioItems = journalistApi.removePortfolioItems;
  public addPortfolioItems = journalistApi.addPortfolioItems;

  // BELGA NOW
  public fetchBelgaNowNewsObjects = newsObjectApi.fetchBelgaNowNewsObjects;

  // BELGA CONTACTS
  public fetchBelgaContacts = belgaContactsApi.fetchBelgaContacts;
  public fetchBelgaContactById = belgaContactsApi.fetchBelgaContactById;
}

export default RestApiService;
