import { matchPath } from 'react-router';
import { Store } from 'redux';

import config from 'config';

import { runInDev } from 'utils/env.utils';
import { hasUsername } from 'utils/user.utils';

import { history } from 'services';
import { sessionStorageService } from 'services';

import { Routes } from 'pages/routes.constants';
import { setRedirectURL } from 'pages/utils';

import { validateAuthentication } from './authentication';
import { UserDisabledError, UserFetchError, UserSettingsFetchError } from './errors';
import { fetchUserAndSettings } from './fetchUserAndSettings';
import { fetchPersonalization } from './personalization';

const redirectFlow = () => {
  const redirectUri = sessionStorageService.getItem(config.SESSION_STORAGE_ID_REDIRECT);
  runInDev(() => console.info('---- Redirect flow ----'));

  if (redirectUri) {
    history.push(redirectUri);
  } else {
    history.push(Routes.KioskFrontPage);
  }
};

const PUBLIC_ROUTES = [Routes.Share, Routes.Unsubscribe, Routes.FeedbackPressReleases];

export default async function initializeApp(store: Store) {
  // public routes so nothing to do
  if (!!matchPath(history.location.pathname, { path: PUBLIC_ROUTES })) return;

  try {
    // store the requested url, could be we do an oauth dance
    setRedirectURL(history.location);
    await validateAuthentication(store);
    const { user, settings } = await fetchUserAndSettings({ store });
    // load personalized data for the user
    await fetchPersonalization(user, settings, store);

    if (!hasUsername(user)) {
      history.push(Routes.Register);
      return;
    }
    // Redirect to the page they originally requested
    redirectFlow();
  } catch (e) {
    switch ((e as Error).name) {
      case UserFetchError.name:
      case UserSettingsFetchError.name:
        history.push(Routes.Maintenance);
        break;
      case UserDisabledError.name:
        history.push(Routes.AccountDisabled);
        break;
      default:
        history.push(Routes.Maintenance);
    }
  }
}
