import { Box, BoxProps, Typography } from '@mui/material';

interface Props extends BoxProps {
  children?: React.ReactNode;
}

const Chip = ({ children, ...rest }: Props) => {
  return (
    <Box
      component="span"
      display="flex"
      height="16px"
      alignItems="center"
      px={1}
      borderRadius={8}
      bgcolor="global01.600"
      color="global01.400"
      textOverflow="ellipsis"
      overflow="hidden"
      maxWidth={100}
      {...rest}
    >
      <Typography
        variant="label01"
        noWrap
        textOverflow="ellipsis"
        textTransform="uppercase"
        fontWeight={600}
        margin="none"
      >
        {children}
      </Typography>
    </Box>
  );
};

export default Chip;
