import { AxiosInstance } from 'axios';

import { Decision } from 'types/feedback-pressrelease.types';
import { NewsObject } from 'types/newsobject.types';
import { PressRelease } from 'types/press-releases.types';

interface Options {
  api: AxiosInstance;
}

class PublicRestApiService {
  public api: AxiosInstance;

  constructor(options: Options) {
    this.api = options.api;
  }
  // newsobject minimal
  public fetchMinimalNewsObject(uuid: string) {
    return this.api.get<NewsObject>(`/newsobjects/${uuid}/minimal`);
  }

  public submitPressReleaseFeedback(
    pressReleaseId: PressRelease['id'],
    token: string,
    decision: Decision,
  ) {
    return this.api.post(
      `/pressreleases/${pressReleaseId}/feedback`,
      { decision },
      {
        headers: {
          'X-Feedback-Token': token,
        },
      },
    );
  }

  public fetchPressReleaseFeedback(pressReleaseId: PressRelease['id'], token: string) {
    return this.api.get<{ decision: Decision | null }>(
      `/pressreleases/${pressReleaseId}/feedback`,
      {
        headers: {
          'X-Feedback-Token': token,
        },
      },
    );
  }

  // subscription
  public unsubscribe(hash: string) {
    return this.api.put(`unsubscribe/${hash}`);
  }

  public unsubscribeSector(hash: string) {
    return this.api.put(`/belga/sectors/member/unsubscribe/${hash}`);
  }
}

export default PublicRestApiService;
