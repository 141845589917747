import {
  ComparisonType,
  DateRangeType,
  DateResolutions,
  PeriodComparison,
  SerializableDateRange,
} from 'utils/date.utils';

import { SourceGroupNames } from 'constants/source.constants';
import { Links } from 'types/api.types';
import { Collaboration, CollaborationParams } from 'types/collaboration.types';
import { PostSearchParams } from 'types/newsobject.types';

export enum AnalysisDatasetType {
  Boards = 'BOARD',
  Labels = 'LABEL',
  Feeds = 'FEED',
  Searches = 'SAVED_SEARCH',
}

export enum SentimentState {
  Positive = 'POSITIVE',
  Negative = 'NEGATIVE',
  Neutral = 'NEUTRAL',
}

// Different chart types, a type can contain multiples charts, eg: sources
export enum ChartTypes {
  SOURCES = 'SOURCES',
  AUDIENCE_REACHED = 'AUDIENCE_REACHED',
  MEDIA_VALUE = 'MEDIA_VALUE',
  SENTIMENT = 'SENTIMENT',
  ARTICLE_COUNT = 'ARTICLE_COUNT',
}

export enum ChartDisplays {
  Bar = 'BAR',
  Line = 'LINE',
  Donut = 'DONUT',
}

// Different chart identifier, mapping to individual charts, eg: sourcegroups
export enum ChartIdentifier {
  ArticleCount = 'articlecount',
  AudienceReach = 'audiencereach',
  MediaValue = 'mediavalue',
  Sentiment = 'sentiment',
  Sources = 'sources',
  SourceGroups = 'sourcegroups',
}

export enum AnalysisOverviewType {
  AudienceReach = 'audienceReach',
  MediaValue = 'mediaValue',
  Sentiment = 'sentiment',
  WrittenArticleCount = 'writtenArticleCount',
  Positioning = 'positioning',
}

export type AnalysisParams = {
  count?: number | string;
  resolution: DateResolutions;
} & PostSearchParams;

export type AnalysisOverview = {
  [AnalysisOverviewType.AudienceReach]?: {
    average: number;
    total: number;
  } | null;
  [AnalysisOverviewType.MediaValue]?: {
    average?: {
      amount: string;
      currency: string;
    };
    total: {
      amount: string;
      currency: string;
    };
  } | null;
  [AnalysisOverviewType.Sentiment]?: {
    score: number;
    state: SentimentState;
  };
  [AnalysisOverviewType.WrittenArticleCount]?: {
    average: number;
    total: number;
  } | null;
  [AnalysisOverviewType.Positioning]?: {
    wordCount: number | null;
    page: number | null;
  } | null;
};

export type SelectedSegment = {
  id: string | number;
  indexValue: string | number;
  amount?: string | number;
  date?: string;
  filters: PostSearchParams;
  datasetIndex: number;
};

export type AnalysisDataset = {
  id: string | number;
  name?: string;
  hexColor?: string;
  type: AnalysisDatasetType;
  filter: PostSearchParams;
  dateRange: SerializableDateRange;
};

export type SentimentType = {
  negative: number;
  neutral: number;
  positive: number;
  average: number;
  slice: string;
};

export type CommonAnalysisType = {
  currency?: string;
} & { [key: string]: number | string };

export type AudienceReachType = {
  audienceReach: number;
  slice: string;
};

export type MediaValueType = {
  amount: number;
  currency: string;
  slice: string;
};

export type ArticleCountType = {
  articleCount: number;
  slice: string;
};

export type SourceGroupType = {
  share: number;
  sourceGroup: SourceGroupNames;
  sourceGroupId: number;
};

export type SourceType = {
  share: number;
  name: string;
  sourceId: number;
  sourceGroup: string;
};

type SentimentMeta = {
  state: SentimentState;
  score: number;
};

export type AverageMeta = {
  average: number;
  total: number;
};

export type MediaValueMeta = {
  average: {
    amount: string;
    currency: string;
  };
  total: {
    amount: string;
    currency: string;
  };
};

type AnalysisResponse<T, M> = {
  data: T[];
  meta: {
    resolution: DateResolutions;
    aggregate: M;
  };
  links: Links;
};

export type SentimentResponse = AnalysisResponse<SentimentType, SentimentMeta>;
export type ArticleCountResponse = AnalysisResponse<ArticleCountType, AverageMeta>;
export type AudienceReachedResponse = AnalysisResponse<AudienceReachType, AverageMeta>;
export type MediaValueResponse = AnalysisResponse<MediaValueType, MediaValueMeta>;

export type SourceResponse = {
  data: SourceType[];
  meta: {
    total: number;
    resolution: DateResolutions;
  };
  links: Links;
};

export type SourceGroupResponse = {
  data: SourceGroupType[];
  meta: {
    total: number;
    resolution: DateResolutions;
  };
  links: Links;
};

export enum FiltersEnum {
  MediumTypeGroup = 'mediumtypegroup',
  SourceGroup = 'sourcegroupid',
  Source = 'sourceid',
  Sentiment = 'sentiment',
  MediaValue = 'mediaValue',
  AudienceReach = 'audienceReach',
  Language = 'language',
  Country = 'country',
  CollapseDuplicates = 'collapseduplicates',
}

export type CheckboxValues = {
  [key: string]: boolean;
};

export type RangeValues = {
  min: string;
  max: string;
};

export type CheckboxFilterPayload = {
  name: FiltersEnum;
  values: CheckboxValues;
};

export type RangeFilterPayload = {
  name: FiltersEnum;
  value: RangeValues;
};

export enum RangeEnum {
  MediaValueMax = 'mediavaluemax',
  MediaValueMin = 'mediavaluemin',
  AudienceReachMin = 'audiencereachmin',
  AudienceReachMax = 'audiencereachmax',
  SentimentMin = 'sentimentmin',
  SentimentMax = 'sentimentmax',
}

export type FilterState = {
  [FiltersEnum.MediumTypeGroup]: CheckboxValues;
  [FiltersEnum.SourceGroup]: CheckboxValues;
  [FiltersEnum.Source]: CheckboxValues;
  [FiltersEnum.Sentiment]: CheckboxValues;
  [FiltersEnum.MediaValue]: RangeValues;
  [FiltersEnum.AudienceReach]: RangeValues;
  [FiltersEnum.Language]: CheckboxValues;
  [FiltersEnum.Country]: CheckboxValues;
  [FiltersEnum.CollapseDuplicates]: boolean;
  excludednewsobjectuuids: string[];
};

export type Analysis = {
  id: number;
  title: string;
  description: string;
  analysisType: ChartTypes;
  chartDisplay: ChartDisplays;
  comparisonType: ComparisonType | null;
  periodComparison: PeriodComparison | null;
  datasets: {
    sourceId: string;
    sourceType: AnalysisDatasetType;
    sourceName: string;
    period: {
      start: string;
      end: string;
      dateRangeType: DateRangeType;
    };
  }[];
  filters: FilterState;
  collaboration: Collaboration;
  createdAt?: string;
  updatedAt?: string;
};

export type SavedAnalysisBody = {
  id?: Analysis['id'];
  updatedAt?: Analysis['updatedAt'];
  title: Analysis['title'];
  analysisType: Analysis['analysisType'];
  datasets: {
    sourceId: number | string;
    sourceType: AnalysisDatasetType;
    sourceName: string;
    period: { start?: string; end?: string; dateRangeType?: DateRangeType };
    hexColor?: string;
  }[];
  chartDisplay: Analysis['chartDisplay'];
  comparisonType: Analysis['comparisonType'];
  periodComparison: Analysis['periodComparison'];
  collaboration: CollaborationParams;
  description?: string;
  filters: PostSearchParams;
  createdAt?: Analysis['createdAt'];
};

export type SavedAnalysisResponse = SavedAnalysisBody & { id: number };

export type SavedAnalysisSearchParams = Partial<{
  count: number;
  offset: number;
  searchtext: string;
  order: string;
}>;

export enum AnalysisEnvironmentContext {
  ANALYSIS = 'analysis',
  PUBLISH = 'publish',
}

export enum DatasetSourceType {
  BOARD = 'BOARD',
  LABEL = 'LABEL',
  FEED = 'FEED',
  SAVED_SEARCH = 'SAVED_SEARCH',
}

export enum DatasetConstraintsType {
  NEWSLETTER = 'NEWSLETTER',
  ANALYSIS = 'ANALYSIS',
  REPORT = 'REPORT',
}

export type DatasetConstraintsParams = {
  sourceId: number | string;
  sourceType: DatasetSourceType;
};

export type DatasetConstraints = {
  id: number | string;
  title: string;
  type: DatasetConstraintsType;
};

export type AnalysisMediaBreakpointState = {
  sm: boolean;
  md: boolean;
  lg: boolean;
};
