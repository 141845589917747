import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { useFeatures } from 'services/feature-flags/hooks';

import { Routes } from 'pages/routes.constants';
import { Route as RouteProps } from 'pages/routes.types';

import { getRouteProtection } from './route-protection';

const RouteProtected = ({ path, userFeatureFlags, ...rest }: RouteProps) => {
  const { flags, isEnabled: hasFeatureFlag } = useFeatures();

  if (!flags?.length) {
    return <Redirect to={Routes.RedirectNotAuthorized} />;
  }

  const userHasAtLeastOneRequiredFeatureFlag = !!userFeatureFlags?.length
    ? userFeatureFlags.some(flag => hasFeatureFlag(flag))
    : true;

  if (!userHasAtLeastOneRequiredFeatureFlag) return <Redirect to={Routes.RedirectNotAuthorized} />;

  const { isAuthorized, redirectTo } = getRouteProtection(path, flags);
  return isAuthorized ? <Route path={path} {...rest} /> : <Redirect to={redirectTo} />;
};

export default React.memo(RouteProtected);
