import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getLanguage } from 'store/intl/intl.selectors';
import { UserLanguages } from 'types/user.types';

export const useLocale = () => {
  const language = useSelector(getLanguage);

  const locale = useMemo(() => {
    switch (language) {
      case UserLanguages.EN:
        return 'enGB';
      case UserLanguages.NL:
        return 'nl';
      case UserLanguages.FR:
        return 'fr';
      default:
        return 'enGB';
    }
  }, [language]);

  return locale;
};
