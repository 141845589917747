import { buttonClasses, Components, Theme } from '@mui/material';

export const MuiButton: Components<Theme>['MuiButton'] = {
  defaultProps: {
    disableRipple: true,
    variant: 'text',
    color: 'primary',
  },
  styleOverrides: {
    root: ({ theme }) => ({
      '&:focus-visible': {
        outline: `2px solid ${theme.palette.global01[500]}`,
      },
      textTransform: 'none',
      transition: theme.transitions.create(['width']),
      [`& .${buttonClasses.startIcon}`]: {
        marginRight: '4px',
        marginLeft: '0',
      },
      [`& svg`]: {
        fontSize: 24,
      },
      fontFamily: theme.typography.fontFamily,
      [`&.${buttonClasses.fullWidth}`]: {
        width: '100%',
      },
      width: 'fit-content',
      minWidth: 'auto',
      [`&.${buttonClasses.disabled}`]: {
        pointerEvents: 'auto',
        cursor: 'not-allowed',
        color: theme.palette.text.disabled,
      },
      letterSpacing: '0',
      overflow: 'hidden',
    }),
    text: ({ theme }) => ({
      borderRadius: 0,
      textTransform: 'inherit',
      fontStyle: 'inherit',
      padding: 0,
      margin: 0,
      '&:hover': {
        backgroundColor: 'transparent',
      },
      [`&.${buttonClasses.disabled}`]: {
        pointerEvents: 'auto',
        cursor: 'not-allowed',
        color: theme.palette.text.disabled,
      },
    }),
    textError: ({ theme }) => ({
      color: theme.palette.error.main,
      '& svg': {
        fill: theme.palette.error.main,
      },

      '&:hover': {
        color: theme.palette.error.dark,
        '& svg': {
          fill: theme.palette.error.dark,
        },
      },
    }),
    textPrimary: ({ theme }) => ({
      borderRadius: 0,
      textTransform: 'inherit',
      fontStyle: 'inherit',
      padding: 0,
      margin: 0,
      color: theme.palette.neutral01[300],
      '& svg': {
        fill: theme.palette.neutral01[300],
      },
      '&:hover:not(.Mui-disabled)': {
        color: theme.palette.primary.main,
        backgroundColor: 'transparent',
        '& svg': {
          fill: theme.palette.primary.main,
        },
      },
      '& span': {
        alignItems: 'center',
      },
      '& span > span': {
        marginRight: '0',
      },
      [`&.${buttonClasses.disabled}`]: {
        '& svg': {
          fill: `${theme.palette.neutral01[400]} !important`,
        },
      },
    }),
    textSecondary: ({ theme }) => ({
      borderRadius: 0,
      textTransform: 'inherit',
      fontStyle: 'inherit',
      padding: 0,
      margin: 0,
      color: theme.palette.global01[400],
      '& svg': {
        fill: theme.palette.global01[400],
      },
      '&:hover:not(.Mui-disabled)': {
        color: theme.palette.global01[300],
        backgroundColor: 'transparent',
        '& svg': {
          fill: theme.palette.global01[300],
        },
      },
      '& span': {
        alignItems: 'center',
      },
      '& span > span': {
        marginRight: '0',
      },
      [`&.${buttonClasses.disabled}`]: {
        '& svg': {
          fill: `${theme.palette.neutral01[400]} !important`,
        },
      },
    }),
    textInfo: ({ theme }) => ({
      borderRadius: 0,
      textTransform: 'inherit',
      fontStyle: 'inherit',
      padding: 0,
      margin: 0,
      color: theme.palette.neutral01[300],
      '& svg': {
        fill: theme.palette.neutral01[300],
      },
      '&:hover:not(.Mui-disabled)': {
        color: theme.palette.neutral01[100],
        backgroundColor: 'transparent',
        '& svg': {
          fill: theme.palette.neutral01[100],
        },
      },
      '& span': {
        alignItems: 'center',
      },
      '& span > span': {
        marginRight: '0',
      },
      [`&.${buttonClasses.disabled}`]: {
        '& svg': {
          fill: `${theme.palette.neutral01[400]} !important`,
        },
      },
    }),
    outlined: () => ({
      fontWeight: 'bold',
      padding: '7px 12px',
      borderRadius: '8px',
    }),
    contained: () => ({
      fontWeight: 'bold',
      padding: '7px 12px',
      borderRadius: '8px',
    }),
    containedPrimary: ({ theme }) => ({
      color: theme.palette.neutral01[700],
      fontWeight: 'bold',
      padding: theme.spacing(1.5, 2),
      height: '40px',
      fontSize: '13px',
      backgroundColor: theme.palette.global01[400],
      '&:hover': {
        backgroundColor: theme.palette.global01[300],
      },
      '&& svg': {
        fill: theme.palette.neutral01[700],
      },
      '&.Mui-disabled': {
        backgroundColor: theme.palette.neutral01[500],
        '&:hover': {
          backgroundColor: theme.palette.neutral01[500],
        },
        '&& svg': {
          fill: theme.palette.neutral01[400],
        },
      },
      '&& span > span': {
        '&::after': {
          border: 'none',
        },
      },
    }),
    containedSecondary: ({ theme }) => ({
      color: theme.palette.global01[400],
      backgroundColor: theme.palette.neutral01[700],
      '&:hover': {
        backgroundColor: theme.palette.global01[600],
      },
      '&& svg': {
        fill: theme.palette.global01[400],
      },
      '&.Mui-disabled': {
        backgroundColor: theme.palette.neutral01[500],
        '&:hover': {
          backgroundColor: theme.palette.neutral01[500],
        },
        '&& svg': {
          fill: theme.palette.neutral01[400],
        },
      },
      '&& span > span': {
        '&::after': {
          border: 'none',
        },
      },
    }),
    containedSuccess: ({ theme }) => ({
      color: theme.palette.neutral01[700],
      fontWeight: 'bold',
      height: '40px',
      padding: theme.spacing(1.5, 2),
      fontSize: '13px',
      backgroundColor: theme.palette.success['main'],
      '&:hover': {
        backgroundColor: theme.palette.success['dark'],
      },
    }),
    containedError: ({ theme }) => ({
      color: theme.palette.neutral01[700],
      backgroundColor: theme.palette.accent01[400],
      '&:hover': {
        backgroundColor: theme.palette.accent01[400],
      },
      '&& svg': {
        fill: theme.palette.neutral01[700],
      },
      '&.Mui-disabled': {
        backgroundColor: theme.palette.neutral01[500],
        '&:hover': {
          backgroundColor: theme.palette.neutral01[500],
        },
        '&& svg': {
          fill: theme.palette.neutral01[400],
        },
      },
      '&& span > span': {
        '&::after': {
          border: 'none',
        },
      },
    }),
    containedInfo: ({ theme }) => ({
      color: theme.palette.neutral01[700],
      fontWeight: 'bold',
      padding: theme.spacing(1.5, 2),
      height: '40px',
      fontSize: '13px',
      backgroundColor: theme.palette.neutral01[100],
      '&:hover': {
        backgroundColor: theme.palette.neutral01[100],
      },
      '&& svg': {
        fill: theme.palette.neutral01[700],
      },
      '&.Mui-disabled': {
        backgroundColor: theme.palette.neutral01[500],
        '&:hover': {
          backgroundColor: theme.palette.neutral01[500],
        },
        '&& svg': {
          fill: theme.palette.neutral01[400],
        },
      },
      '&& span > span': {
        '&::after': {
          border: 'none',
        },
      },
    }),
    outlinedPrimary: ({ theme }) => ({
      height: '40px',
      padding: theme.spacing(1.5, 2),

      [`&.${buttonClasses.root}`]: {
        backgroundColor: theme.palette.background.default,
        '& svg': {
          fill: theme.palette.primary.main,
        },
      },
      '&:hover': {
        backgroundColor: theme.palette.background.default,
      },
      [`&.${buttonClasses.disabled}`]: {
        '&:hover': {
          backgroundColor: theme.palette.background.default,
        },
        '& svg': {
          fill: theme.palette.text.disabled,
        },
      },
    }),
    outlinedInfo: ({ theme }) => ({
      height: '40px',
      padding: theme.spacing(1.5, 2),

      [`&.${buttonClasses.root}`]: {
        backgroundColor: 'transparent',
        color: theme.palette.neutral01[100],
        borderColor: theme.palette.neutral01[100],
        '& svg': {
          fill: theme.palette.neutral01[100],
        },
      },
      '&:hover': {
        backgroundColor: 'transparent',
      },
      [`&.${buttonClasses.disabled}`]: {
        '&:hover': {
          backgroundColor: theme.palette.background.default,
        },
        '& svg': {
          fill: theme.palette.text.disabled,
        },
      },
    }),
  },
};
