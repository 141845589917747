import type { AxiosResponse } from 'axios';

import {
  belgaContextHeaders,
  removeEmptyValuesFromParams,
} from 'services/api/utils/remove-empty-values';
import { ApiResponse } from 'types/api.types';
import { NewsObject, NewsObjectContext } from 'types/newsobject.types';
import {
  Newsroom,
  NewsroomPressReleaseInsightsDetail,
  NewsroomPressReleasesInsights,
  NewsroomPressReleasesInsightsGlobals,
  NewsroomPressReleasesInsightsParams,
} from 'types/newsroom.types';
import {
  CreatePressReleaseParams,
  KioskPressRelease,
  KioskPressReleasesParams,
  PressRelease,
  PressReleasePickups,
  PressReleasePublishSettings,
  PressReleaseSearchParams,
  PublishPressReleaseParams,
  RelatedPressReleaseNewsParams,
  Sector,
  UpdatePressReleaseParams,
  UpdatePressReleasePublishSettingsParams,
} from 'types/press-releases.types';
import { User } from 'types/user.types';

import RestApiService from '.';

export function createPressRelease(
  this: RestApiService,
  newsroomId: Newsroom['id'],
  params: CreatePressReleaseParams,
) {
  return this.api.post<CreatePressReleaseParams, AxiosResponse<PressRelease>>(
    `/newsrooms/${newsroomId}/pressreleases`,
    params,
  );
}

export function fetchNewsroomPressReleases(
  this: RestApiService,
  newsroomId: Newsroom['id'],
  dirtyParams: PressReleaseSearchParams = {},
) {
  const params = removeEmptyValuesFromParams(dirtyParams);

  return this.api.get<ApiResponse<PressRelease[]>>(`/newsrooms/${newsroomId}/pressreleases`, {
    params,
  });
}
export function fetchMinimalNewsroomPressReleases(
  this: RestApiService,
  newsroomId: Newsroom['id'],
  dirtyParams: PressReleaseSearchParams = {},
) {
  const params = removeEmptyValuesFromParams(dirtyParams);

  return this.api.get<ApiResponse<PressRelease[]>>(
    `/newsrooms/${newsroomId}/pressreleases/minimal`,
    {
      params,
    },
  );
}

export function fetchPressReleasePickups(
  this: RestApiService,
  userId: User['id'],
  newsroomId: Newsroom['id'],
  pressReleaseId: PressRelease['id'],
) {
  return this.api.get<PressReleasePickups>(
    `/users/${userId}/kiosk/newsrooms/${newsroomId}/pressreleases/${pressReleaseId}/pickups`,
  );
}

export function fetchPressReleaseRelatedNewsobjects(
  this: RestApiService,
  userId: User['id'],
  newsroomId: Newsroom['id'],
  pressReleaseId: PressRelease['id'],
  context: NewsObjectContext,
  params: RelatedPressReleaseNewsParams,
) {
  const cleanParams = removeEmptyValuesFromParams(params);

  return this.api.get<ApiResponse<NewsObject[]>>(
    `/users/${userId}/kiosk/newsrooms/${newsroomId}/pressreleases/${pressReleaseId}/newsobjects/related`,
    {
      params: cleanParams,
      headers: belgaContextHeaders(context),
    },
  );
}

export function deletePressRelease(
  this: RestApiService,
  newsroomId: Newsroom['id'],
  pressReleaseId: PressRelease['id'],
) {
  return this.api.delete(`/newsrooms/${newsroomId}/pressreleases/${pressReleaseId}`);
}

export function fetchPressReleasePublishSettings(
  this: RestApiService,
  newsroomId: Newsroom['id'],
  pressReleaseId: PressRelease['id'],
) {
  return this.api.get<PressReleasePublishSettings>(
    `/newsrooms/${newsroomId}/pressreleases/${pressReleaseId}/publishsettings`,
  );
}

export function duplicatePressRelease(
  this: RestApiService,
  newsroomId: Newsroom['id'],
  pressReleaseId: PressRelease['id'],
) {
  return this.api.post<PressRelease>(
    `/newsrooms/${newsroomId}/pressreleases/${pressReleaseId}/duplicate`,
  );
}

export function fetchPressReleaseById(
  this: RestApiService,
  newsroomId: Newsroom['id'],
  pressReleaseId: PressRelease['id'],
) {
  return this.api.get<PressRelease>(`/newsrooms/${newsroomId}/pressreleases/${pressReleaseId}`);
}
export function fetchKioskPressReleaseById(
  this: RestApiService,
  userId: User['id'],
  newsroomId: Newsroom['id'],
  pressReleaseId: PressRelease['id'],
  headers?: Record<string, string>,
) {
  return this.api.get<PressRelease>(
    `/users/${userId}/kiosk/newsrooms/${newsroomId}/pressreleases/${pressReleaseId}`,
    { headers },
  );
}
export function fetchKioskNewsroomPressReleases(
  this: RestApiService,
  userId: User['id'],
  newsroomId: Newsroom['id'],
  params: PressReleaseSearchParams,
) {
  return this.api.get<ApiResponse<PressRelease[]>>(
    `/users/${userId}/kiosk/newsrooms/${newsroomId}/pressreleases`,
    { params },
  );
}

export function fetchKioskPressReleases(
  this: RestApiService,
  userId: User['id'],
  params: KioskPressReleasesParams,
) {
  return this.api.get<ApiResponse<KioskPressRelease[]>>(
    `/users/${userId}/kiosk/newsrooms/pressreleases`,
    { params },
  );
}

export function updatePressRelease(
  this: RestApiService,
  newsroomId: Newsroom['id'],
  pressReleaseId: PressRelease['id'],
  params: UpdatePressReleaseParams,
) {
  return this.api.put<UpdatePressReleaseParams, AxiosResponse<PressRelease>>(
    `/newsrooms/${newsroomId}/pressreleases/${pressReleaseId}`,
    params,
  );
}

export function publishPressRelease(
  this: RestApiService,
  newsroomId: Newsroom['id'],
  pressReleaseId: PressRelease['id'],
  params: PublishPressReleaseParams,
) {
  return this.api.put<PressRelease>(
    `/newsrooms/${newsroomId}/pressreleases/${pressReleaseId}/publish`,
    params,
  );
}

export function unpublishPressRelease(
  this: RestApiService,
  newsroomId: Newsroom['id'],
  pressReleaseId: PressRelease['id'],
) {
  return this.api.put<PressRelease>(
    `/newsrooms/${newsroomId}/pressreleases/${pressReleaseId}/unpublish`,
    {},
  );
}

export function fetchSectors(this: RestApiService) {
  return this.api.get<ApiResponse<Sector[]>>(`/belga/sectors`);
}

export function updatePressReleasePublishSettings(
  this: RestApiService,
  newsroomId: Newsroom['id'],
  pressReleaseId: PressRelease['id'],
  params: UpdatePressReleasePublishSettingsParams,
) {
  return this.api.put<
    UpdatePressReleasePublishSettingsParams,
    AxiosResponse<PressReleasePublishSettings>
  >(`/newsrooms/${newsroomId}/pressreleases/${pressReleaseId}/publishsettings`, params);
}

export function fetchPressReleasesInsightsGlobals(
  this: RestApiService,
  newsroomId: Newsroom['id'],
) {
  return this.api.get<NewsroomPressReleasesInsightsGlobals>(
    `/newsrooms/${newsroomId}/insights/pressreleases/globals`,
  );
}

export function fetchPressReleasesInsights(
  this: RestApiService,
  newsroomId: Newsroom['id'],
  params: NewsroomPressReleasesInsightsParams,
) {
  return this.api.get<ApiResponse<NewsroomPressReleasesInsights[]>>(
    `/newsrooms/${newsroomId}/insights/pressreleases`,
    { params },
  );
}

export function fetchPressReleaseInsightsDetail(
  this: RestApiService,
  newsroomId: Newsroom['id'],
  pressReleaseId: PressRelease['id'],
) {
  return this.api.get<NewsroomPressReleaseInsightsDetail>(
    `/newsrooms/${newsroomId}/insights/pressreleases/${pressReleaseId}`,
  );
}
