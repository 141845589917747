import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

import { isDev } from 'utils/env.utils';

import { notificationService } from 'services';
import { SnackbarTypes } from 'types/notification.types';

// Added this to ignore snippets exceptions
const exceptionTypeToIgnore = 'UnknownContentTypeException';

// We want to phase out the toast message on 500 errors, but we need to keep it for now
// When adding a new endpoint, add it to this list to ignore the toast message and make sure to show an error state in the UI
const ignoredEndpoints = [
  '/users/:userId/belga/contacts',
  '/users/:userId/belga/contacts/:contactId',
];

export const createResponseErrorInterceptor = (axios: AxiosInstance) => {
  axios.interceptors.response.use(
    (res: AxiosResponse) => res,
    async (error: AxiosError<{ errorCode: string; message: string; exceptionType: string }>) => {
      const isQuickIntegrationNotFound =
        error?.request?.responseURL.includes('quickintegrations') &&
        error?.response?.status === 404;

      if (
        error.response &&
        error.response.status >= 500 &&
        exceptionTypeToIgnore !== error.response?.data?.exceptionType &&
        !error.config.meta?.ignoreError &&
        !isQuickIntegrationNotFound
      ) {
        const { errorCode, message, exceptionType } = error.response.data || {};

        const title =
          exceptionType || errorCode
            ? [exceptionType, errorCode].filter(Boolean).join(' - ')
            : `status - ${error.response?.status}`;

        // TODO: Remove this when backend fixes maintenance messages on staging
        if (error?.response?.config?.url === '/maintenancemessages' && isDev()) {
          return Promise.reject(error);
        }

        if (
          ignoredEndpoints.some(endpoint => {
            const regex = new RegExp(endpoint.replace(/:[^\s/]+/g, '[^/]+'));
            return regex.test(error.config.url || '');
          })
        ) {
          return Promise.reject(error);
        }

        notificationService.add({
          title: `Error: ${title}`,
          message,
          type: SnackbarTypes.Error,
        });
      }

      return Promise.reject(error);
    },
  );
};
