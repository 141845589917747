import React, { useMemo } from 'react';
import { Redirect, Route } from 'react-router-dom';

import { useFeatures } from 'services/feature-flags/hooks';

import { Routes } from 'pages/routes.constants';
import { Route as RouteProps } from 'pages/routes.types';

const RoutePublic = ({ userFeatureFlags, ...rest }: RouteProps) => {
  const features = useFeatures();

  // If one of the feature flags is enabled, the route is enabled
  const isEnabled = useMemo(() => {
    if (!userFeatureFlags?.length) {
      return true;
    }
    return userFeatureFlags.some(flag => features?.isEnabled(flag));
  }, [features, userFeatureFlags]);

  return isEnabled ? <Route {...rest} /> : <Redirect to={Routes.NotFound} />;
};

export default React.memo(RoutePublic);
