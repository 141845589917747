import { Box, MenuItem, Typography } from '@mui/material';
import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { generatePath, Link } from 'react-router-dom';

import config from 'config';

import { testId } from 'utils/test-id-generator.utils';

import { logout } from 'services/authentication/logout';
import { FeatureGate } from 'services/feature-flags/FeatureGate';
import { User, UserFeatureFlags } from 'types/user.types';

import { Routes } from 'pages/routes.constants';

import Icon, { IconNames } from 'components/@icons';
import DropdownMenu from 'components/@inputs/DropdownMenu';

import Trigger from './Trigger';

interface Props {
  user: User;
}

const UserSettings = ({ user }: Props) => {
  const intl = useIntl();

  const actions: {
    label: I18nKey;
    featureFlag: UserFeatureFlags;
    testId: string;
    icon: IconNames;
    to?: string;
    isExternal?: boolean;
    onClick?: () => void;
  }[] = useMemo(
    () => [
      {
        label: 'navigation.user.settings',
        featureFlag: UserFeatureFlags.USER_SETTINGS,
        to: generatePath(Routes.Settings),
        icon: 'IcoSettings',
        isExternal: false,
        testId: testId('navigation-user-dropdown-item', 'settings')['data-testid'],
      },
      {
        label: 'navigation.user.change_password',
        featureFlag: UserFeatureFlags.USER_CHANGE_PASSWORD,
        to: config.API_CHANGE_PASSWORD_URL,
        icon: 'IcoInfo',
        isExternal: true,
        testId: testId('navigation-user-dropdown-item', 'change_password')['data-testid'],
      },
      {
        label: 'navigation.user.feedback',
        featureFlag: UserFeatureFlags.USER_FEEDBACK,
        to: intl.formatMessage({ id: 'navigation.user.feedback.url' }),
        icon: 'IcoEdit',
        isExternal: true,
        testId: testId('navigation-user-dropdown-item', 'feedback')['data-testid'],
      },
      {
        label: 'navigation.user.help',
        featureFlag: UserFeatureFlags.USER_HELP,
        to: intl.formatMessage({ id: 'navigation.user.help.url' }),
        icon: 'IcoHelp',
        isExternal: true,
        testId: testId('navigation-user-dropdown-item', 'help')['data-testid'],
      },
      {
        label: 'navigation.user.logout',
        featureFlag: UserFeatureFlags.USER_LOGOUT,
        onClick: () => logout(),
        icon: 'IcoLogout',
        isExternal: true,
        testId: testId('navigation-user-dropdown-item', 'logout')['data-testid'],
      },
    ],
    [intl],
  );

  return (
    <Box pl={2}>
      <DropdownMenu
        align="right"
        trigger={({ onClick, isOpen }) => <Trigger onClick={onClick} isOpen={isOpen} user={user} />}
        margin={1}
      >
        <Typography
          variant="paragraph01"
          color="neutral01.300"
          sx={theme => ({
            padding: '12px 16px',
            borderBottom: `1px solid ${theme.palette.neutral01[700]}`,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            pointerEvents: 'none',
          })}
        >
          {user.email}
        </Typography>

        {actions.map(({ label, featureFlag, to, isExternal, onClick, icon, testId }) => (
          <FeatureGate key={label} flags={featureFlag}>
            <Box>
              <MenuItem
                component={isExternal ? 'a' : Link}
                to={onClick ? undefined : isExternal ? undefined : to}
                href={onClick ? undefined : isExternal ? to : undefined}
                target={isExternal ? '_blank' : undefined}
                rel={isExternal ? 'noopener noreferrer' : undefined}
                onClick={onClick}
                data-testid={testId}
              >
                <Box display="flex" alignItems="center">
                  <Icon sx={{ mr: 1 }} name={icon} />
                  <Typography variant="paragraph01" color="neutral01.300">
                    <FormattedMessage id={label} />
                  </Typography>
                </Box>
              </MenuItem>
            </Box>
          </FeatureGate>
        ))}
      </DropdownMenu>
    </Box>
  );
};

export default UserSettings;
