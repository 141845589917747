import React from 'react';

import { UserFeatureFlags } from 'types/user.types';

import { Routes } from 'pages/routes.constants';

const componentPromise = import('./TopicsDetailRoute');
const Component = React.lazy(() => componentPromise);

/**
 * custom params
 * - authenticated: if true > renders authenticated route > else > renders public route
 */
export default {
  component: Component,
  exact: true,
  path: [Routes.KioskTopicsDetail, Routes.KioskTopicsDetailArticle],
  authenticated: true,
  userFeatureFlags: [UserFeatureFlags.TOPICS],
};
