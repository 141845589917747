import { noop } from 'lodash-es';
import { createContext } from 'react';

import { featureFlagService } from 'services';

import FeatureFlagService from './FeatureFlagService';

export const FeatureGateContext = createContext<FeatureFlagService>({
  flags: [],
  allFlags: [],
  setUserFeatureFlags: noop,
  isEnabled: () => false,
  isDisabled: () => false,
  isAnyEnabled: () => false,
  areAllEnabled: () => false,
  isAnyDisabled: () => false,
  areAllDisabled: () => false,
});

export const FeatureGateProvider = ({ children }: { children: React.ReactNode }) => (
  <FeatureGateContext.Provider value={featureFlagService}>{children}</FeatureGateContext.Provider>
);
