import { UserFeatureFlags } from 'types/user.types';

import { Routes } from 'pages/routes.constants';

import { IconNames } from 'components/@icons';

export type MenuItem = {
  name: string;
  key: I18nKey;
  route: Routes;
  icon?: IconNames;
  aliasRoutes?: Routes[];
  userFeatureFlags: UserFeatureFlags[];
  isNew?: boolean;
  // If the menu item is optional, it will be displayed in the menu only if there are other children in the same menu that are accessible
  isOptional?: boolean;
};

type WebConfigItem = {
  name: string;
  key: I18nKey;
  route: Routes;
  aliasRoutes?: Routes[];
  isNew?: boolean;
  children: MenuItem[];
};

export const WebConfig: WebConfigItem[] = [
  {
    name: 'explore',
    key: 'navigation.main.explore',
    route: Routes.Explore,
    children: [
      {
        name: 'kiosk',
        key: 'navigation.main.kiosk',
        route: Routes.KioskFrontPage,
        aliasRoutes: [Routes.KioskShared, Routes.KioskFrontPage],
        userFeatureFlags: [UserFeatureFlags.KIOSK],
      },
      {
        name: 'boards',
        key: 'navigation.main.boards',
        route: Routes.Boards,
        userFeatureFlags: [UserFeatureFlags.BOARDS],
      },
      {
        name: 'labels',
        key: 'navigation.main.labels',
        route: Routes.Labels,
        userFeatureFlags: [UserFeatureFlags.LABELS],
      },
      {
        name: 'documents',
        key: 'navigation.main.documents',
        route: Routes.Documents,
        userFeatureFlags: [UserFeatureFlags.MY_DOCUMENTS],
      },
      {
        name: 'belga_contacts',
        key: 'navigation.main.belga_contacts',
        route: Routes.BelgaContacts,
        userFeatureFlags: [UserFeatureFlags.JOURNALIST_NEWSROOM],
      },
    ],
  },
  {
    name: 'publish',
    key: 'navigation.main.publish',
    route: Routes.Publish,
    children: [
      {
        name: 'feeds',
        key: 'navigation.main.feeds',
        route: Routes.Feeds,
        userFeatureFlags: [UserFeatureFlags.FEEDS],
      },
      {
        name: 'newsletters',
        key: 'navigation.main.newsletters',
        route: Routes.Newsletters,
        userFeatureFlags: [UserFeatureFlags.NEWSLETTERS],
      },
      {
        name: 'reports',
        key: 'navigation.main.reports',
        route: Routes.Reports,
        userFeatureFlags: [UserFeatureFlags.ANALYSIS],
      },
      {
        name: 'contacts',
        key: 'navigation.main.contacts',
        route: Routes.ContactsPublish,
        userFeatureFlags: [UserFeatureFlags.EDIT_CONTACT_MANAGEMENT],
        isOptional: true,
      },
    ],
  },
  {
    name: 'analysis',
    key: 'navigation.main.analysis',
    route: Routes.Analysis,
    children: [
      {
        name: 'exploratory_analysis',
        key: 'navigation.main.exploratory_analysis',
        route: Routes.ExploratoryAnalysis,
        userFeatureFlags: [UserFeatureFlags.ANALYSIS_PREVIEW, UserFeatureFlags.ANALYSIS],
      },
      {
        name: 'saved_analyses',
        key: 'navigation.main.saved_analyses',
        route: Routes.SavedAnalyses,
        userFeatureFlags: [UserFeatureFlags.ANALYSIS_PREVIEW, UserFeatureFlags.ANALYSIS],
      },
    ],
  },
  {
    name: 'belga_share',
    key: 'navigation.main.belga_share',
    route: Routes.BelgaShare,
    children: [
      {
        name: 'newsrooms',
        key: 'navigation.main.press_releases',
        route: Routes.Newsrooms,
        userFeatureFlags: [UserFeatureFlags.NEWSROOMS, UserFeatureFlags.NEWSROOMS_PREVIEW],
      },
      {
        name: 'journalists',
        key: 'navigation.main.journalist',
        route: Routes.Journalist,
        userFeatureFlags: [UserFeatureFlags.JOURNALIST_NEWSROOM],
      },
      {
        name: 'contacts',
        key: 'navigation.main.contacts',
        route: Routes.ContactsShare,
        userFeatureFlags: [UserFeatureFlags.EDIT_CONTACT_MANAGEMENT],
        isOptional: true,
      },
    ],
  },
];

export const MobileConfig: MenuItem[] = [
  {
    name: 'kiosk',
    key: 'navigation.mobile.kiosk',
    route: Routes.KioskFrontPage,
    icon: 'IcoKiosk',
    userFeatureFlags: [UserFeatureFlags.KIOSK],
  },
  {
    name: 'boards',
    key: 'navigation.main.boards',
    route: Routes.Boards,
    icon: 'IcoOverview',
    userFeatureFlags: [UserFeatureFlags.BOARDS],
  },
  {
    name: 'labels',
    key: 'navigation.mobile.labels',
    route: Routes.Labels,
    icon: 'IcoTags',
    userFeatureFlags: [UserFeatureFlags.LABELS],
  },
  {
    name: 'search',
    key: 'navigation.mobile.search',
    route: Routes.Search,
    icon: 'IcoSearch',
    userFeatureFlags: [UserFeatureFlags.SEARCH],
  },
];
