import { UserFeatureFlags } from 'types/user.types';

import { useFeatures } from './hooks';

interface FeatureGateProps {
  flags: UserFeatureFlags[] | UserFeatureFlags;
  condition?: Condition;
  fallback?: React.ReactNode;
  children: React.ReactNode;
}

export enum Condition {
  AND = 'AND', // All flags must be enabled
  OR = 'OR', // At least one flag must be enabled
  NOT = 'NOT', // All flags must be disabled
}

export const FeatureGate = ({
  flags,
  condition = Condition.AND,
  fallback,
  children,
}: FeatureGateProps) => {
  const { isAnyEnabled, areAllDisabled, areAllEnabled } = useFeatures();

  const featureFlags = typeof flags === 'string' ? [flags] : flags;

  if (condition === Condition.AND && areAllEnabled(featureFlags)) return <>{children}</>;
  if (condition === Condition.OR && isAnyEnabled(featureFlags)) return <>{children}</>;
  if (condition === Condition.NOT && areAllDisabled(featureFlags)) return <>{children}</>;

  if (fallback) return <>{fallback}</>;
  return null;
};
