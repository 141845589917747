import { BrowserOptions } from '@sentry/react';

import { UserFeatureFlags, UserLanguages } from 'types/user.types';

export interface EnvBase {
  NAME: string;
  VERSION: string;
  BUILD_ENV: string;
  ENABLE_DEV_TOOLS: boolean;
  DEFAULT_CURRENCY: string;
  DEFAULT_LOCALE: UserLanguages;
  DEFAULT_LOCALE_DATE: string;
  SENTRY: BrowserOptions;
  SESSION_STORAGE_ID_REDIRECT: string;
  LOCAL_STORAGE_ID: string;
  LOCAL_REACT_QUERY_STORAGE_ID: string;
  API_FEATURE_FLAGS: UserFeatureFlags[];
}

export interface EnvJson {
  API_BASE: string;
  API_CALLBACK_URL: string;
  API_SHARE_URL: string;
  API_BASE_AUTH: string;
  API_CHANGE_PASSWORD_URL: string;
  API_CLIENT_ID: string;
  API_KEYCLOAK: {
    SCOPE: string;
    RESPONSE_TYPE: string;
  };
  STRAPI_API_BASE: string;
  STRAPI_API_KEY: string;
  API_KEYCLOAK_SCOPE: string;
  API_KEYCLOAK_RESPONSE_TYPE: string;
  ANALYTICS_GOOGLE_TRACKING_ID: string;
  ANALYTICS_GOOGLE_DEBUG: boolean;
  POSTHOG_KEY: string;
  GOOGLE_MAPS_API_KEY: string;
  SOURCE_ID_BELGA_SHARE: number;
  SOURCE_ID_BELGA_IMAGE: number;
  SOURCE_ID_BELGA_WIRE: number;
  MEDIUM_TYPE_GROUP_ID_BELGA: number;
  USETIFUL_KEY: string;
}

class EnvService {
  NAME = '';
  VERSION = '';
  BUILD_ENV = '';
  ENABLE_DEV_TOOLS = false;
  PORT = 4200;
  DEFAULT_CURRENCY = 'EUR';
  DEFAULT_LOCALE = 'EN' as UserLanguages;
  DEFAULT_LOCALE_DATE = 'en';
  SENTRY = {};
  SESSION_STORAGE_ID_REDIRECT = 'nf-bena-0002-redirect';
  LOCAL_STORAGE_ID = 'nf-bena-0002';
  LOCAL_REACT_QUERY_STORAGE_ID = 'nf-bena-0002-react-query';

  API_BASE = '';
  API_CALLBACK_URL = '';
  API_SHARE_URL = '';
  API_BASE_AUTH = '';
  API_CHANGE_PASSWORD_URL = '';
  API_CLIENT_ID = '';
  API_KEYCLOAK = {
    SCOPE: '',
    RESPONSE_TYPE: '',
  };
  ANALYTICS_GOOGLE_TRACKING_ID = '';
  ANALYTICS_GOOGLE_DEBUG = false;
  API_FEATURE_FLAGS: UserFeatureFlags[] = [];
  POSTHOG_KEY = '';
  GOOGLE_MAPS_API_KEY = '';
  SOURCE_ID_BELGA_SHARE = 486;
  SOURCE_ID_BELGA_IMAGE = 345;
  SOURCE_ID_BELGA_WIRE = 340;
  MEDIUM_TYPE_GROUP_ID_BELGA = 4;
  STRAPI_API_BASE = '';
  STRAPI_API_KEY = '';
  USETIFUL_KEY = '';

  constructor(config: EnvBase) {
    this.NAME = config.NAME || this.NAME;
    this.VERSION = config.VERSION || this.VERSION;
    this.BUILD_ENV = config.BUILD_ENV || this.BUILD_ENV;
    this.DEFAULT_CURRENCY = config.DEFAULT_CURRENCY || this.DEFAULT_CURRENCY;
    this.DEFAULT_LOCALE = config.DEFAULT_LOCALE || this.DEFAULT_LOCALE;
    this.ENABLE_DEV_TOOLS = config.ENABLE_DEV_TOOLS || this.ENABLE_DEV_TOOLS;
    this.SENTRY = config.SENTRY || this.SENTRY;
    this.SESSION_STORAGE_ID_REDIRECT =
      config.SESSION_STORAGE_ID_REDIRECT || this.SESSION_STORAGE_ID_REDIRECT;
    this.LOCAL_STORAGE_ID = config.LOCAL_STORAGE_ID || this.LOCAL_STORAGE_ID;
    this.LOCAL_REACT_QUERY_STORAGE_ID =
      config.LOCAL_REACT_QUERY_STORAGE_ID || this.LOCAL_REACT_QUERY_STORAGE_ID;
    this.API_FEATURE_FLAGS = config.API_FEATURE_FLAGS || this.API_FEATURE_FLAGS;
  }

  setRemoteConfig(config: EnvJson) {
    // TODO: remove env.process fallback once pipelines are in order
    this.API_BASE = config.API_BASE || process.env.REACT_APP_API_BASE || this.API_BASE;
    this.STRAPI_API_BASE = config.STRAPI_API_BASE || process.env.STRAPI_API_BASE || '';
    this.STRAPI_API_KEY = config.STRAPI_API_KEY || process.env.REACT_APP_STRAPI_API_KEY || '';
    this.POSTHOG_KEY = config.POSTHOG_KEY || process.env.POSTHOG_KEY || this.POSTHOG_KEY;
    this.SOURCE_ID_BELGA_SHARE =
      Number(config.SOURCE_ID_BELGA_SHARE) ||
      Number(process.env.SOURCE_ID_BELGA_SHARE) ||
      this.SOURCE_ID_BELGA_SHARE;
    this.SOURCE_ID_BELGA_IMAGE =
      Number(config.SOURCE_ID_BELGA_IMAGE) ||
      Number(process.env.SOURCE_ID_BELGA_IMAGE) ||
      this.SOURCE_ID_BELGA_IMAGE;
    this.SOURCE_ID_BELGA_WIRE =
      Number(config.SOURCE_ID_BELGA_WIRE) ||
      Number(process.env.SOURCE_ID_BELGA_WIRE) ||
      this.SOURCE_ID_BELGA_WIRE;
    this.MEDIUM_TYPE_GROUP_ID_BELGA =
      Number(config.MEDIUM_TYPE_GROUP_ID_BELGA) ||
      Number(process.env.MEDIUM_TYPE_GROUP_ID_BELGA) ||
      this.MEDIUM_TYPE_GROUP_ID_BELGA;
    this.GOOGLE_MAPS_API_KEY =
      config.GOOGLE_MAPS_API_KEY || process.env.GOOGLE_MAPS_API_KEY || this.GOOGLE_MAPS_API_KEY;
    this.API_CALLBACK_URL =
      config.API_CALLBACK_URL || process.env.REACT_API_CALLBACK_URL || this.API_CALLBACK_URL;
    this.API_SHARE_URL =
      config.API_SHARE_URL || process.env.REACT_API_SHARE_URL || this.API_SHARE_URL;
    this.API_BASE_AUTH =
      config.API_BASE_AUTH || process.env.REACT_API_BASE_AUTH || this.API_BASE_AUTH;
    this.API_CHANGE_PASSWORD_URL =
      config.API_CHANGE_PASSWORD_URL ||
      process.env.REACT_API_CHANGE_PASSWORD_URL ||
      this.API_CHANGE_PASSWORD_URL;
    this.API_CLIENT_ID =
      config.API_CLIENT_ID || process.env.REACT_API_CLIENT_ID || this.API_CLIENT_ID;
    this.API_KEYCLOAK = config.API_KEYCLOAK || process.env.REACT_API_KEYCLOAK || this.API_KEYCLOAK;
    this.ANALYTICS_GOOGLE_DEBUG =
      config.ANALYTICS_GOOGLE_DEBUG ||
      !!process.env.REACT_ANALYTICS_GOOGLE_DEBUG ||
      this.ANALYTICS_GOOGLE_DEBUG;
    this.ANALYTICS_GOOGLE_TRACKING_ID =
      config.ANALYTICS_GOOGLE_TRACKING_ID ||
      process.env.REACT_ANALYTICS_GOOGLE_TRACKING_ID ||
      this.ANALYTICS_GOOGLE_TRACKING_ID;
    this.USETIFUL_KEY = config.USETIFUL_KEY || process.env.REACT_USETIFUL_KEY || this.USETIFUL_KEY;
  }
}

export default EnvService;
