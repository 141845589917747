import { ApiResponse } from 'types/api.types';
import { BelgaContact, BelgaContactsSearchParams } from 'types/belga-contacts.types';
import { User } from 'types/user.types';

import RestApiService from '.';

export function fetchBelgaContacts(
  this: RestApiService,
  userId: User['id'],
  params: BelgaContactsSearchParams,
) {
  return this.api.get<ApiResponse<BelgaContact[]>>(`/users/${userId}/belga/contacts`, {
    params,
  });
}

export function fetchBelgaContactById(
  this: RestApiService,
  userId: User['id'],
  contactId: BelgaContact['id'],
) {
  return this.api.get<BelgaContact>(`/users/${userId}/belga/contacts/${contactId}`);
}
