import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { loadUsetifulScript, setUsetifulTags } from 'usetiful-sdk';

import config from 'config';

import { useCurrentUser } from 'queries/user';
import { useFeatures } from 'services/feature-flags';
import { getLanguage } from 'store/intl/intl.selectors';
import { UserFeatureFlags } from 'types/user.types';

const useUsetiful = () => {
  const user = useCurrentUser();
  const userLanguage = useSelector(getLanguage);
  const { allFlags } = useFeatures();

  loadUsetifulScript(config.USETIFUL_KEY);

  useEffect(() => {
    if (!user) return;

    setUsetifulTags({
      userId: user.id,
      organization: user.organization.name,
      language: userLanguage,
      ...allFlags.reduce(
        (acc, flag) => {
          acc[flag] = flag;
          return acc;
        },
        {} as Record<UserFeatureFlags, UserFeatureFlags>,
      ),
    });
  }, [allFlags, user, userLanguage]);
};

export default useUsetiful;
